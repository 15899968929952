import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Constants } from 'shared/types';

const ConstantsContext = React.createContext<Constants | undefined>(undefined);

export const ConstantsProvider = ({ children }: PropsWithChildren) => {
  const [constants, setConstants] = useState<Constants | undefined>(undefined);

  useEffect(() => {
    fetch('/load-constants', {
      method: 'GET',
    })
      .then((res) => {
        if (!res.ok) {
          setConstants(undefined);
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => setConstants(res))
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <ConstantsContext.Provider value={constants}>
      {children}
    </ConstantsContext.Provider>
  );
};

export default ConstantsContext;
