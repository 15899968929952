import Block from 'components/Block';
import { useFormikContext } from 'formik';
import GrantTypeCard from 'pages/configurator/components/GrantTypeCard';
import { getGrantTypes } from 'shared/grantTypes';
import { FormData, GrantTypeEnum, MeasureKey } from 'shared/types';

interface GrantTypeSwitcherProps {
  isBabickaEligible: boolean;
  onGrantTypeSelect: (grantType: GrantTypeEnum) => void;
  toggleSetting: (measure: MeasureKey) => void;
}

function GrantTypeSwitcher(props: GrantTypeSwitcherProps) {
  const { values } = useFormikContext<FormData>();
  const grantTypes = getGrantTypes(values);
  const handleOnGrantSelect = (key: GrantTypeEnum) => {
    // turn off project documentation if selected grant type is NZU
    if (key === GrantTypeEnum.NZUL) {
      props.toggleSetting(MeasureKey.Documentation);
    }
    props.onGrantTypeSelect(key);
  };

  return values.permanentResidence &&
    values.complexReconstruction &&
    values.anotherPermanentResidence === false ? (
    <Block>
      <h5 className="mb-4">Doporučená dotace</h5>
      <div className="grid sm:grid-cols-2 gap-4">
        {Object.entries(grantTypes).map(([key, value]) => {
          const isBabickaGrantSelectable = props.isBabickaEligible
            ? 'selectable'
            : 'static';
          const type =
            key === GrantTypeEnum.Babicka
              ? isBabickaGrantSelectable
              : 'selectable';
          return (
            <GrantTypeCard
              key={key}
              {...value}
              type={type}
              available={type === 'selectable'}
              selected={key === values.grantType}
              onSelect={() => handleOnGrantSelect(key as GrantTypeEnum)}
            />
          );
        })}
      </div>
    </Block>
  ) : null;
}

export default GrantTypeSwitcher;
