import ExclamationTriangle from 'assets/icons/exclamation-triangle-yellow.svg';
import InfoCircle from 'assets/icons/info-circle.svg';
import Block from 'components/Block';
import Input from 'components/form/Input';
import SliderWithInput from 'components/form/SliderWithInput';
import { useFormikContext } from 'formik';
import Recap from 'pages/configurator/components/Recap';
import LoanVariant from 'pages/configurator/steps/financing/components/LoanVariant';
import { availableVariants, getLoanVariants } from 'shared/financing';
import { measuresByClass } from 'shared/measure';
import {
  GrantTypeEnum,
  Loan,
  MeasureSetting,
  ValidatedFormData,
} from 'shared/types';

interface FinancingStepProps {
  loans: Loan[];
  total: number;
  saving: number;
  setting: MeasureSetting;
}

function FinancingStep(props: FinancingStepProps) {
  const { values } = useFormikContext<ValidatedFormData>();
  const { loanLength } = values;
  const loanAmount = Math.max(props.total - values.ownSavings, 0);
  const { A, C } = measuresByClass(props.setting);

  const isBabickaEligible =
    values.complexReconstruction &&
    values.permanentResidence &&
    values.anotherPermanentResidence === false;
  const loanVariants = getLoanVariants(props.loans, loanAmount, loanLength)
    .filter((loan) => {
      const showLoan = loan.onlyWithBabicka ? isBabickaEligible : true;
      return showLoan ? loan : null;
    })
    // TODO: prenest lepsi logiku do cms, aby byla konfigurovatelna a jednotna napric aplikaci
    .filter((loan) => {
      if (loan.id === 1) {
        if (values.grantType === GrantTypeEnum.Babicka) {
          return A.length >= 2 && C.length >= 1 ? loan : null;
        } else {
          // for NZU if at least one of A measures is selected, babicka is eligible
          return A.length >= 1 ? loan : null;
        }
      }
      return loan;
    });

  return (
    <>
      <h2>Možnosti financování</h2>
      <Recap total={props.total} />
      <Block headline="Kolik máte vlastních úspor?">
        <Input
          type="number"
          min={0}
          max={loanAmount}
          step={1000}
          unit="Kč"
          name="ownSavings"
        />
      </Block>
      <Recap loanAmount={loanAmount} saving={props.saving} />
      {loanAmount ? (
        <>
          <div className="mb-12">
            <h4 className="mb-2">Jak dlouho chcete splácet?</h4>
            <SliderWithInput />
          </div>
          <div className="mb-8">
            <h4 className="mb-4">Předběžně doporučujeme tyto úvěry</h4>
            <h5 className="mb-4">
              Finální návrh financování je možné řešit až po získání nabídky od
              stavebních firem.
            </h5>
            {availableVariants(props.loans, loanAmount, loanLength) === 0 && (
              <div className="flex items-center space-x-2 border-warning border-2 p-2.5 mb-4">
                <img src={ExclamationTriangle} alt="Warning" />
                <span>Za daných podmínek se pro vás nehodí žádný úvěr.</span>
              </div>
            )}
            <div className="space-y-4">
              {
                /* only first three loans */
                loanVariants.slice(0, 3).map((loan) => (
                  <LoanVariant key={loan.name} {...loan} />
                ))
              }
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <img src={InfoCircle} alt="Info" />
            <span>Nejedná se o návrh na uzavření smlouvy.</span>
          </div>
        </>
      ) : null}
    </>
  );
}

export default FinancingStep;
