import CheckCircleGreen from 'assets/icons/check-circle-green.svg';
import Block from 'components/Block';
import Radio from 'components/form/Radio';
import Select from 'components/form/Select';
import MessageBox from 'components/MessageBox';
import { useFormikContext } from 'formik';
import GrantTypeCard from 'pages/configurator/components/GrantTypeCard';
import { useEffect } from 'react';
import { getGrantTypes } from 'shared/grantTypes';
import { GrantTypeEnum } from 'shared/types';

export interface GrantFormData {
  anotherPermanentResidence?: boolean;
  children?: number;
  complexReconstruction?: boolean;
  permanentResidence?: boolean;
}

export interface GrantTypeStepProps {
  skipChildren?: boolean;
}

const childrenSelectOptions = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10 a více',
};

function GrantTypeStep<FormType extends GrantFormData>(
  props: GrantTypeStepProps,
) {
  const { values, setFieldValue } = useFormikContext<FormType>();
  const grantTypes = getGrantTypes(values);

  useEffect(() => {
    if (
      values.complexReconstruction &&
      values.permanentResidence &&
      values.anotherPermanentResidence === false
    ) {
      setFieldValue('grantType', GrantTypeEnum.Babicka);
    } else {
      setFieldValue('grantType', GrantTypeEnum.NZUL);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const bothGrantsIneligible =
    values.permanentResidence === false ||
    values.anotherPermanentResidence === true;

  return (
    <>
      <h2>Podívejme se, jaká dotace je pro vás vhodná</h2>
      <Block headline="Plánujete v domě, jehož rekonstrukci teď počítáme, bydlet dalších pět let?">
        <Radio name="permanentResidence" inline />
      </Block>
      <Block
        headline="Vlastníte více než dvě nemovitosti určené k trvalému bydlení?"
        tooltip="Stavbou k trvalému bydlení myslíme rodinný dům, bytový dům, byt v bytovém domě nebo členský podíl v bytovém družstvu."
      >
        <Radio name="anotherPermanentResidence" inline />
      </Block>
      <Block
        headline="Plánujete dům komplexně rekonstruovat?"
        tooltip="Podmínkou je zateplení celé obálky budovy včetně podlah i stropů."
      >
        <Radio name="complexReconstruction" inline />
      </Block>
      <Block headline="Splňujete podmínky pro následující dotaci/e">
        <div className="grid sm:grid-cols-2 gap-4">
          {Object.entries(grantTypes).map(([key, value]) => (
            <GrantTypeCard key={key} type="static" {...value} />
          ))}
        </div>
        <div className="flex gap-4 items-center mt-14">
          <img src={CheckCircleGreen} alt="Attribute" />
          <p>
            Oba dotační programy můžete čerpat pro{' '}
            <strong>stejná úsporná opatření</strong> a poskytují{' '}
            <strong>
              stejnou výši finanční podpory pro úsporné technologie
            </strong>
            .
          </p>
        </div>
      </Block>
      {!props.skipChildren && grantTypes[GrantTypeEnum.Babicka].available && (
        <>
          <h5 className="mb-6">
            Podmínky pro získání finančního bonusu v rámci dotace Oprav dům po
            babičce
          </h5>
          <Block
            headline="Kolik máte nezaopatřených dětí?"
            copy="Za každé nezaopatřené dítě obdržíte rodinný bonus 50 000 Kč"
            tooltip="Nezaopatřeným dítětem se rozumí dítě do skončení povinné školní docházky. Také poté, nejdéle však do 26. roku věku, pokud se soustavně připravuje na budoucí povolání."
          >
            <Select
              name="children"
              // placeholder="Vyberte počet dětí"
              options={childrenSelectOptions}
            />
          </Block>
        </>
      )}
      {bothGrantsIneligible && (
        <MessageBox
          text="Podle zadaných údajů nejste oprávněn/a k žádné z dotací."
          severity="info"
        />
      )}
    </>
  );
}

export default GrantTypeStep;
