import { ErrorEvent } from "@sentry/core";
import { getRoute, RouteEnum } from 'helpers/routes';
import { StepEnum } from 'helpers/steps';
import { FormData } from 'shared/types';

const scriptDev = 'https://assets.adobedtm.com/0e0efd248c1a/011a20cdb621/launch-64260167617a-development.js';
const scriptAcc = 'https://assets.adobedtm.com/0e0efd248c1a/011a20cdb621/launch-ff3cad5f8655-staging.min.js';
const scriptProd = 'https://assets.adobedtm.com/0e0efd248c1a/011a20cdb621/launch-52947e2f71c8.min.js';

const LOGGING = false;

function getScript(env: string) {
  return env === 'production' ? scriptProd : env === 'acceptance' ? scriptAcc : scriptDev;
}

export function addAnalytics() {
  const script = document.createElement('script');
  const env = process.env.REACT_APP_ENV ?? process.env.NODE_SERVER_ENV ?? process.env.NODE_ENV;
  script.src = getScript(env);
  script.async = true;
  document.body.appendChild(script);
}

// Cannot be put in useEffect(), because analytics are not loaded at that time yet
export const analyticsSetup = () => {
  document.documentElement.setAttribute('data-analytics-loaded', 'true');
  const { pathname } = window.location;
  trackPageView(pathname);
};

export const isAnalyticsLoaded = () => document.documentElement.getAttribute('data-analytics-loaded') === 'true';

const analyticsPageNameMapping: { [key in RouteEnum]: string } = {
  [RouteEnum.HomePage]: 'Main Page',
  [RouteEnum.Configurator]: 'Form - Konfigurator',
  [RouteEnum.Grant]: 'Form - Subsidy',
  [RouteEnum.HowToApply]: 'Form - Process',
  [RouteEnum.NotFound]: 'Page not found',
};

const getPageId = (pathname: string) => {
  const route = getRoute(pathname);
  return analyticsPageNameMapping[route];
};

export function trackPageView(pathname: string) {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  if (LOGGING) console.log('Track page view:', getPageId(pathname));

  try {
    // @ts-ignore
    measure({
      action: 'pageView',
      pageId: getPageId(pathname),
    });
  } catch (err) {
    console.error(err);
  }

  const route = getRoute(pathname);
  if (route === RouteEnum.Configurator || route === RouteEnum.Grant || route === RouteEnum.HowToApply) {
    isAnalyticsLoaded() && trackStep(1);
  }
}

const analyticsConfiguratorStepNameMapping: { [key in StepEnum]: string } = {
  [StepEnum.Step1]: '01-Data Type',
  [StepEnum.Step2]: '02-Data Dimensions',
  [StepEnum.Step3]: '03-Data Roof',
  [StepEnum.Step4]: '04-Data Windows',
  [StepEnum.Step5]: '05-Data Energy',
  [StepEnum.GrantType]: '06-Appropriate Subsidy',
  [StepEnum.Savings]: '07-Savings',
  [StepEnum.Results]: '08-Proposed Reconstruction',
  [StepEnum.Financing]: '09-Financing Options',
  [StepEnum.Contact]: '10-Summary',
  [StepEnum.ThankYou]: '11-Form Finished',
};

const analyticsGrantStepNameMapping: { [key: number]: string } = {
  1: '01-Subsidy Selection',
  2: '02-Subsidy Comparison',
  3: '03-Summary',
};

const analyticsHowToApplyStepNameMapping: { [key: number]: string } = {
  1: '01-Necessary Documents',
  2: '02-Summary',
};

export function trackStep(step: number, formValues?: FormData) {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  const route = getRoute(window.location.pathname);
  let formId;
  let formStep;

  if (route === RouteEnum.Configurator) {
    formId = 'HBMSR Konfigurator';
    formStep = analyticsConfiguratorStepNameMapping[step as StepEnum];
  }
  if (route === RouteEnum.Grant) {
    formId = 'HBMSR Subsidy';
    formStep = analyticsGrantStepNameMapping[step];
  }
  if (route === RouteEnum.HowToApply) {
    formId = 'HBMSR Process';
    formStep = analyticsHowToApplyStepNameMapping[step];
  }

  const extraData =
    step === StepEnum.ThankYou && !!formValues
      ? {
          conversionEmail: formValues.email ? 'yes' : 'no',
          conversionPhone: formValues.partner === false && formValues.tel ? 'yes' : 'no',
          marketingConsent: formValues.partner === false && formValues.agreement ? 'yes' : 'no',
        }
      : {};

  if (LOGGING) console.log('Track step:', formId, formStep, extraData);

  try {
    // @ts-ignore
    measure({
      action: 'formStepView',
      formid: formId,
      formstep: formStep,
      ...extraData,
    });
  } catch (err) {
    console.error(err);
  }
}

export function trackCalculationStarted() {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  if (LOGGING) console.log('Track calculation started');

  try {
    // @ts-ignore
    measure({
      action: 'calculationStarted',
      calc: 'HBMSR Konfigurator',
    });
  } catch (err) {
    console.error(err);
  }
}

export function trackLoanClicked(loanName: string) {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  if (LOGGING) console.log('Track loan clicked:', loanName);

  try {
    // @ts-ignore
    measure({
      action: 'elementClicked',
      elementName: `Reprezentatiní příklad:${loanName}`,
    });
  } catch (err) {
    console.error(err);
  }
}

export function trackHomePageButtonClicked(buttonId: string) {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  if (LOGGING) console.log('Track home page button clicked:', buttonId);

  try {
    // @ts-ignore
    measure({
      action: 'elementClicked',
      elementName: buttonId,
      mainPageConversion: true,
    });
  } catch (err) {
    console.error(err);
  }
}

export function trackReset() {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  if (LOGGING) console.log('Track reset');

  try {
    // @ts-ignore
    measure({
      action: 'formReturn',
      returnType: 'reset',
    });
  } catch (err) {
    console.error(err);
  }
}

export function trackLeadFormSent(isFromHomePage?: boolean) {
  if (!isAnalyticsLoaded()) {
    return null;
  }
  const elementName = isFromHomePage ? '03-Získám návrh na financování' : undefined;
  const additionalData = elementName ? { elementName } : {};
  if (LOGGING) console.log('Track lead form sent:', additionalData);

  try {
    // @ts-ignore
    measure({
      action: 'leadSent',
      conversionPhone: 'yes',
      ...additionalData,
    });
  } catch (err) {
    console.error(err);
  }
}

export function filterOutSentryEvents(event: ErrorEvent) {
  const ignoreList = [
    'Failed to load script https://assets.adobedtm.com',
  ];

  if (event.exception?.values) {
    event.exception.values = event.exception.values.filter(
      (exception) => exception.value && !ignoreList.includes(exception.value)
    );
  }

  return event;
}