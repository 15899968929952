import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from 'components/Button';
import Cycle from 'assets/icons/cycle.svg';

interface NavigationProps {
  nextLabel?: string;
  nextLabelMobile?: string;
  nextClick?: (mobile: boolean) => void;
  isNextSubmit?: boolean;
  backLabel?: string;
  backLabelMobile?: string;
  backClick?: () => void;
  backIcon?: 'chevron-left' | 'cycle';
}

function Navigation(props: NavigationProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const primaryButtonRef = useRef<HTMLButtonElement>(null);
  const linkButtonRef = useRef<HTMLButtonElement>(null);
  const [isEnoughSpace, setIsEnoughSpace] = useState(true);

  const updateIsEnoughSpace = () => {
    if (containerRef.current && linkButtonRef.current && primaryButtonRef.current) {
      setIsEnoughSpace(
        containerRef.current.clientWidth - 20 * 2 >
          linkButtonRef.current.clientWidth + primaryButtonRef.current.clientWidth + 18,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateIsEnoughSpace);
    return () => window.removeEventListener('resize', updateIsEnoughSpace);
  }, []);

  useEffect(updateIsEnoughSpace, [props]);

  return (
    <div
      className={classNames('flex', 'flex-wrap', 'items-center', 'gap-4', 'border-t', 'p-5', {
        'justify-end': !props.backLabel,
        'justify-between': props.backLabel && isEnoughSpace,
        'justify-center': props.backLabel && !isEnoughSpace,
      })}
      ref={containerRef}
    >
      {props.backLabel && (
        <Button
          label={props.backLabel}
          labelMobile={props.backLabelMobile}
          type="link"
          onClick={() => props.backClick?.()}
          direction="left"
          icon={props.backIcon === 'cycle' ? Cycle : undefined}
          buttonRef={linkButtonRef}
        />
      )}
      {props.nextLabel && (
        <>
          <div className="inline lg:hidden">
            <Button
              label={props.nextLabelMobile ?? props.nextLabel}
              type="secondary"
              isSubmit={props.isNextSubmit}
              onClick={() => props.nextClick?.(true)}
              buttonRef={primaryButtonRef}
            />
          </div>
          <div className="hidden lg:inline">
            <Button
              label={props.nextLabel}
              type="secondary"
              isSubmit={props.isNextSubmit}
              onClick={() => props.nextClick?.(false)}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Navigation;
