import {
  BaseFormData,
  FormAndContactData,
  GrantTypeEnum,
  HeatingSourceEnum,
  HotWaterSourceEnum,
  HouseAgeEnum,
  RoofTypeEnum,
} from './types';

export const mockFormDataHouse: BaseFormData = {
  anotherPermanentResidence: false,
  area: 100,
  attic: true,
  basement: true,
  children: 2,
  doors: 2,
  complexReconstruction: true,
  floors: 2,
  fvInstalled: false,
  grant: 0,
  grantType: GrantTypeEnum.NZUL,
  hasFireplace: false,
  heatingSource: HeatingSourceEnum.GasBoiler,
  heatingSourceAge: 100,
  houseAge: HouseAgeEnum.Since1977,
  houseMembers: 4,
  hotWaterSource: HotWaterSourceEnum.ElectricBoiler,
  hotWaterSourceAge: 100,
  permanentResidence: true,
  roofType: RoofTypeEnum.Gable,
  windowsLarge: 3,
  windowsMedium: 6,
  windowsSmall: 3,
};

const mockFormData = {
  ...mockFormDataHouse,
  loanLength: 20,
  ownSavings: 300000,
  email: 'pavel.hasala@etnetera.cz',
};

export const mockFormDataNoPartner: FormAndContactData = {
  ...mockFormData,
  partner: false,
  tel: '+420777777777',
  agreement: true,
};

export const mockFormDataPartner: FormAndContactData = {
  ...mockFormData,
  partner: true,
  partnerName: 'Jan Novák',
  partnerTel: '+420737767898',
  partnerEmail: 'novakjan@csob.cz',
  partnerType: 'obchodní zástupce',
};
