import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { formatNumber } from 'shared/number';
import { HIDE_SAVINGS } from 'shared/global';

type MeasureMobileProps = PropsWithChildren & {
  price: number;
  grant?: number;
  saving?: number;
  disabled?: boolean;
  className?: string;
  bigHeader?: boolean;
};

function MeasureMobile(props: MeasureMobileProps) {
  return (
    <div className={classNames('grid', 'grid-cols-2', props.className)}>
      <div
        className={classNames('col-span-2', 'p-2', {
          'bg-table-border': !props.bigHeader,
          'bg-primary': props.bigHeader,
          'py-5': props.bigHeader,
        })}
      >
        {props.children}
      </div>
      <div className="bg-lightblue px-2.5 py-2 border-l border-b border-table-border-color">
        <h6>Cena</h6>
      </div>
      <div className="px-2.5 py-2 border-b border-r border-table-border-color">
        {props.disabled ? '–' : formatNumber(props.price, 'Kč')}
      </div>
      {props.grant !== undefined && (
        <>
          <div className="bg-lightblue px-2.5 py-2 border-l border-b border-table-border-color">
            <h6>Dotace</h6>
          </div>
          <div className="px-2.5 py-2 border-b border-r border-table-border-color">
            {props.disabled ? '–' : formatNumber(props.grant, 'Kč')}
          </div>
        </>
      )}
      {props.saving !== undefined && !HIDE_SAVINGS && (
        <>
          <div className="bg-lightblue px-2.5 py-2 border-l border-b border-table-border-color">
            <h6>Úspora měsíčně</h6>
          </div>
          <div className="px-2.5 py-2 border-b border-r border-table-border-color">
            {formatNumber(props.saving, 'Kč')}
          </div>
        </>
      )}
    </div>
  );
}

export default MeasureMobile;
